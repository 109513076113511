<route>
{
  "meta": {
    "auth": "statisticsVendorRank"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="14">
          <el-date-picker
            value-format="timestamp"
            v-model="searchValue"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker
        ></el-col>

        <el-col :span="8">
          <el-input style="display: none"></el-input>
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="rank" label="排行"> </el-table-column>
        <el-table-column align="center" prop="businessName" label="商家昵称"> </el-table-column>
        <el-table-column align="center" prop="orderCount" label="订单个数"> </el-table-column>
        <el-table-column align="center" prop="money" label="订单金额"> </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      list: []
    }
  },

  methods: {
    resetSearch() {
      this.searchValue = []
      this.getList()
    },
    // 请求后端获商家排行列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      this.$api.statistics
        .statistics('businessList', {
          params: {
            startTime: this.searchValue ? this.searchValue[0] : '',
            endTime: this.searchValue ? this.searchValue[1] : '',
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.records.map((item, index) => {
            console.log(parseInt(res.current))
            item.rank = (parseInt(res.current) - 1) * 10 + (index + 1)
            return item
          })
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less"></style>
